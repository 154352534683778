import { graphql } from "gatsby";

import { MarkdownRenderer } from "../common/Markdown";
import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { CallToAction } from "../components/CallToAction";
import { TestimonialsCarousel } from "../components/Carousel";
import { BlogPostHero } from "../components/Hero";


interface Props {
  data: any
};


const PostTemplate = ({
  data
}: Props ) => {
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const post = data?.prismicPost?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ post.title.text }
        description={ post.excerpt }
      />

      <SectionWrapper
        background="linear-gradient(#FBF7F1 0%, #FBF7F1 65%, white 65%, white 100%)"
      >
        <BlogPostHero
          heading={ post.title.text }
          author={ post.author.text }
          postDate={ post.post_date }
          image={ post.image.gatsbyImageData }
          alt={ post.image.alt }
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <MarkdownRenderer
            body={ post.post_body.html }
            description={ post.excerpt }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ post.testimonials_heading.text }
          description={ post.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  );
};


export const query = graphql`
  query Post(
    $id: String!
  ) {
    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
          uid
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicPost(
      id: {
        eq: $id
      }
    ) {
      _previewable
      data {
        title {
          text
        }
        
        post_date(
          formatString: "MMMM DD, YYYY"
        )
        author {
          text
        }
        excerpt
        image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }
        post_body {
          html
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
      uid
    }
  }
`;


export default PostTemplate;
