import * as React from "react";
import {
  Box,
  Container,
  Divider,
  Heading
} from "@chakra-ui/react";


interface Props {
  body: any
  description?: string | undefined
};


export const MarkdownRenderer = ({
  body,
  description
}: Props ) => {
  return (
    <Container
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '776px'
      }}
      w="100%"
      padding="0"
    >
      <Box
        padding="0 0 84px"
        color="accent.900"
      >
        { description &&
          <Heading
            as="h2"
            fontSize="28px"
            fontWeight={ 400 }
            lineHeight="33px"
          >
            { description }
          </Heading>
        }
        <Box
          as="article"
          className="markdown"
          dangerouslySetInnerHTML={{
            __html: body || [],
          }}
        />
      </Box>
      <Divider />
    </Container>
  )
}
